.ProfessionModal {
  &-field-container {
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin: 30px 10px;
  }

  &-field-label {
    font-weight: 500;
    width: 150px;
  }

  &-field-input {
    flex-grow: 1;

    input {
      border-radius: 5px;
      font-weight: 400;
    }

    .ant-input[disabled] {
      color: black !important;
    }
  }
}

.Sider {
  &-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 30px;

    @media screen and (min-width: 1120px) {
      margin: 50px 20px;
    }

    .ant-divider {
      display: none;

      @media screen and (min-width: 1120px) {
        display: block;
      }
    }
  }

  &-nav-item {
    border-radius: 5px;
    color: gray;
    cursor: pointer;
    font-size: 0.95rem;
    padding: 10px 20px;
    position: relative;
    transition: all 0.3s ease;
    width: 100%;

    @media screen and (min-width: 650px) {
      width: 300px;
    }

    @media screen and (min-width: 800px) {
      width: 350px;
    }

    @media screen and (min-width: 1120px) {
      width: 100%;
    }

    .UpcomingBadge-container {
      position: absolute;
      right: 0;
      top: 12px;
    }

    &:hover {
      background: #f3f3f3;
      color: black;
      font-weight: 400;
    }

    &-focused {
      background: #e6e6e6;
      color: black;
      font-weight: 400;
    }
  }
}

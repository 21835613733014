.PicModal {
  &-field-container {
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin: 30px 10px;
  }

  &-field-label {
    font-weight: 500;
    width: 150px;
  }

  &-field-input {
    flex-grow: 1;

    input {
      border-radius: 5px;
      font-weight: 400;
    }

    .ant-input[disabled] {
      color: black !important;
    }
  }

  &-modal {
    .ant-modal-body {
      background: white;
    }

    .ant-modal-header {
      border: unset;
    }

    .ant-modal-footer {
      align-items: flex-start;
      border: unset;
      display: flex;
      justify-content: center;
      min-height: 80px;
    }
  }

  &-dropzone {
    align-items: center;
    background: #f3f3f3;
    border: 1px dashed #c4c4c4;
    border-radius: 5px;
    color: #999;
    display: flex;
    height: 100px;
    justify-content: center;
    width: 100%;
  }

  &-preview {
    &-container {
      display: flex;
      justify-content: center;
      width: 100%;

      img {
        background-size: contain;
        border: 1px solid gray;
        height: auto;
        max-height: 300px;
        max-width: 300px;
      }
    }

    &-img {
      box-shadow: 0 0 12px -1px rgb(196, 196, 196);
      position: relative;

      i {
        font-size: 1.1rem;
      }
    }
  }

  &-clear {
    align-items: center;
    background-color: #999;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    padding: 5px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(5px, -10px);
    width: 30px;
  }
}

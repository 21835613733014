@import "~antd/dist/antd.css";
@import "react-sortable-tree.scss";
@import "react-calendar.scss";

::selection {
  background: var(--theme-primary);
}

html {
  --theme-dark: #2777f4; // theme dark color
  --theme-primary: #1f89f6; // theme primary color
  --theme-light: #1897f8; // theme light color
  --theme-hover-light: #dde4fc; // theme hover light color
  --theme-task-no-priority: #bcbac6;
  --theme-task-low-priority: #61befc;
  --theme-task-middle-priority: #feab4c;
  --theme-task-top-priority: #fb4e87;
  --theme-secondary-dark: gray;
  --theme-secondary-main: #abafbb;
  --theme-secondary-light: #edeff3;
  --theme-delete-main: #fa4d88;
  --theme-delete-light: #fc6899;
  --theme-disabled: #c5d5f4;
  --theme-focus: #cee7ff;
}

body {
  --antd-wave-shadow-color: var(--theme-primary);
}

.ant-btn-primary {
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);

  &:focus {
    background-color: var(--theme-light);
    border-color: var(--theme-light);
  }

  &:hover {
    background-color: var(--theme-light);
    border-color: var(--theme-light);
  }
}

.ant-menu-item-selected {
  color: var(--theme-primary);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--theme-light);
}

.ant-input-affix-wrapper {
  &:hover {
    border-color: var(--theme-light);
  }

  &:focus {
    border-color: var(--theme-light);
  }
}

.ant-input:hover {
  border-color: var(--theme-light);
}

.ant-input:focus,
.ant-input-focused {
  border-color: var(--theme-light);
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: var(--theme-light);
  border-right-width: 1px !important;
  box-shadow: 0 0 0 2px rgba(134, 111, 235, 0.2);
  outline: 0;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
}

.ant-checkbox-checked::after {
  border: 1px solid var(--theme-primary);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--theme-primary);
}

.ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: var(--theme-primary) !important;
  color: white !important;
}

.ant-picker {
  border-radius: 5px;
}

.ant-btn:hover,
.ant-btn:focus {
  background: #fff;
  border-color: var(--theme-primary);
  color: var(--theme-primary);
}

.ant-layout-header {
  background: var(--theme-dark);
}

.ant-spin {
  color: var(--theme-primary);
}

.ant-spin-dot-item {
  background: var(--theme-primary);
}

a {
  color: var(--theme-primary);
}

a:hover {
  color: var(--theme-light);
}

.ant-menu-item a:hover,
.ant-menu-item:hover {
  color: var(--theme-primary);
}

.ant-modal-body {
  background: var(--theme-secondary-light);
}

.ant-modal-header {
  padding: 22px 24px;
}

.ant-modal-footer {
  padding: 16px;

  .ant-btn {
    border: unset !important;
    box-shadow: none;

    &:hover {
      color: var(--theme-delete-main);
    }
  }

  .ant-btn-primary {
    background: var(--theme-primary);
    border-radius: 5px;

    &:hover {
      background-color: var(--theme-light);
      color: white;
    }

    &:disabled {
      background-color: var(--theme-disabled);
      color: white;
    }

    &:focus {
      background-color: var(--theme-disabled);
      color: white;
    }
  }
}

.ant-modal-content {
  border-radius: 15px;
  overflow: auto;
}

.ant-select-item-option {
  border-radius: 5px;
  font-weight: 300;
  margin: 3px 15px;
}

.ant-select-selector {
  border-radius: 5px !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #bbbbc7;
  color: #fff;
  font-weight: 300;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--theme-primary);
}

.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--theme-primary);
  box-shadow: 0 0 0 2px #f3f3f3;
}

.ant-select-dropdown {
  border-radius: 5px;
}

.ant-input-number,
.ant-input-number-handler-wrap {
  border-radius: 5px;
}

.ant-input-number:hover {
  border-color: var(--theme-primary);
}

.ant-input-number:focus,
.ant-input-number-focused {
  border-color: var(--theme-primary);
  box-shadow: 0 0 0 2px rgba(243, 243, 243, 0.2);
}

.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: var(--theme-primary);
}

.ant-picker-focused {
  border-color: var(--theme-primary);
  box-shadow: 0 0 0 2px rgb(238, 238, 238);
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: var(--theme-primary);
}

.ant-picker-time-panel-column > .ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: var(--theme-light);
  color: #fff;
}

.rst__placeholder {
  margin: 0 15px;
  max-width: none;
  min-height: 50px;
}

.rst__placeholder::before {
  border: unset;
}

.rst__absoluteLineBlock {
  display: none;
}

.rst__placeholderLandingPad::before,
.rst__placeholderCancelPad::before {
  background-color: #e0dfe2;
}

// priority based custom antd checkbox

/* stylelint-disable no-descending-specificity */

/* top priority styles */
.top-priority {
  .ant-checkbox-inner {
    background-image: url("/top-priority.svg");
    background-size: cover;
    border: 1px solid var(--theme-task-top-priority) !important;
    border-color: var(--theme-task-top-priority) !important;
  }

  .ant-checkbox-checked::after {
    border: 1px solid var(--theme-task-top-priority);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--theme-task-top-priority);
  }
}

/* middle priority styles */
.middle-priority {
  .ant-checkbox-inner {
    background-image: url("/middle-priority.svg");
    background-size: cover;
    border: 1px solid var(--theme-task-middle-priority) !important;
    border-color: var(--theme-task-middle-priority) !important;
  }

  .ant-checkbox-checked::after {
    border: 1px solid var(--theme-task-middle-priority);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--theme-task-middle-priority);
  }
}

/* low priority styles */
.low-priority {
  .ant-checkbox-inner {
    background-image: url("/low-priority.svg");
    background-size: cover;
    border: 1px solid var(--theme-task-low-priority) !important;
    border-color: var(--theme-task-low-priority) !important;
  }

  .ant-checkbox-checked::after {
    border: 1px solid var(--theme-task-low-priority);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--theme-task-low-priority);
  }
}

/* no priority styles */
.no-priority {
  .ant-checkbox-inner {
    background-image: url("/no-priority.svg");
    background-size: cover;
    border: 1px solid var(--theme-task-no-priority) !important;
    border-color: var(--theme-task-no-priority) !important;
  }

  .ant-checkbox-checked::after {
    border: 1px solid var(--theme-task-no-priority);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--theme-task-no-priority);
  }
}

/* stylelint-enable no-descending-specificity */

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: var(--theme-primary);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: var(--theme-primary);
}

.ant-picker-today-btn,
.ant-picker-header-view button:hover,
.ant-picker-today-btn:hover {
  color: var(--theme-primary);
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--theme-primary);
}

.ant-radio-inner::after {
  background-color: var(--theme-primary);
}

.Form-card {
  background: #ffffff;
  box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  width: 435px;
  @media (max-width: 534px) {
    width: 100%;
  }
  &-title {
    font-family: Roboto, sans-serif;
    font-weight: bold !important;
    font-size: 19px;
    line-height: 24px;
    color: #333 !important;
  }

  .ant-card-body {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px 40px;
    @media (max-width: 534px) {
      padding: 40px 30px;
    }
  }

  button {
    margin-top: 7px;
    width: 100%;
  }

  h4 {
    color: var(--theme-primary);
    font-weight: 600;
    margin-bottom: 20px;

    @media screen and (min-width: 500px) {
      margin-bottom: 30px;
    }
  }

  &.ant-form-item-has-error .ant-input-affix-wrapper:not(.ant-form-item-has-error .ant-input-affix-wrapper-disabled) {
    background: #f3f4f6;
  }
  &.ant-form-item-has-error .ant-input:not(.ant-form-item-has-error .ant-input-disabled) {
    background: #f3f4f6;
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: none;
    border: 1px solid rgba(49, 97, 241, 0.59) !important;
    outline: none;
    background: #fff !important;
  }
  .Form-card-item {
    margin-bottom: 11px;
    min-width: 100%;
    &.Form-cardd-item-focused {
      .ant-form-item-control-input,
      .ant-input-affix-wrapper {
        background: #fff;
      }
    }
    .ant-form-item-control-input {
      background: #f3f4f6;
      border-radius: 6px;
    }
    .ant-input-affix-wrapper {
      min-height: 52px;
      background: lighten(#8990a4, 37%);

      border-radius: 6px;
      padding: 0 27px;
      border: none;
      .ant-input-prefix {
        color: #bbb9c6;
        margin-right: 18px;
      }

      .ant-input-suffix {
        color: #bbb9c6;
        cursor: pointer;
      }

      input {
        background: transparent;
        &::placeholder {
          font-family: Roboto, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: rgba(#000, 0.2);
        }
      }
    }
  }
}

.Form-submit-btn {
  margin-bottom: 15px;
  min-height: 50px;
  height: 50px;
  background: #3161f1;
  border-radius: 5px;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 16px !important;
  color: #ffffff;
  line-height: 2;
  &:hover {
    background-color: darken(#3161f1, 20);
    color: white;
  }

  &:focus {
    background-color: var(--theme-dark);
    border-color: var(--theme-dark);
    color: white;
  }
}

.Form-btn-or {
  height: 50px;
  background: #ffffff;
  border: 0.5px solid #d6d6d6;
  border-radius: 5px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px !important;
  color: #757575;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #757575;
    border: 0.8px solid rgba(49, 97, 241, 0.6);
  }
}

.Form-text-extra {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #8b8b8b;
  a {
    color: #3161f1;
  }
}

.Form-extra-label {
  color: #8b8b8b;
}

.Form {
  &-body {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    max-width: 100vw;
    min-height: 100vh;
    a:hover {
      text-decoration: underline;
    }
  }

  &-content {
    margin: 40px 30px;
  }
  &-logo-container {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
  }
  &-divider-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 11px;
    margin-top: 9px;
    width: 100%;

    h4 {
      margin-bottom: 0;
      padding: 0 10px;
      font-family: Roboto, sans-serif;
      font-weight: 500;
      font-size: 14px;
      color: #000000;
      opacity: 0.3;
    }
  }

  &-password-options-container {
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;

    h4 {
      font-weight: 400;
      padding-top: 4px;
    }

    h4:hover {
      color: var(--theme-light);
    }
    .ant-row.ant-form-item {
      margin-bottom: 0;
    }
  }
  &-forgot-password {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px;
    color: #3161f1 !important;
    margin-bottom: 0 !important;
  }

  &-btn-icon {
    flex: 1 0 30px;
    max-width: 30px;
    margin-right: 12px;
  }

  &-btn-label {
    flex: 1;
    display: inline-flex !important;
    width: 150px;
  }

  &-btn-content {
    display: inline-flex;
  }

  &-divider {
    background: #c2c2c2;
    flex-grow: 1;
    height: 1px;
    margin-top: 12px;
  }

  &-error {
    left: 50%;
    min-width: 300px;
    position: absolute;
    top: 15%;
    transform: translate(-50%, 0);
    z-index: 200;
  }

  &-register-options-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 32px;
    h4 {
      margin-bottom: 0;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 500 !important;
      font-size: 15px !important;
      color: #757575;
      a {
        color: #3161f1 !important;
      }
    }
  }

  &-register-title {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 15px !important;
    color: #757575;
  }

  &-checkbox {
    color: #bcbac6;
    font-weight: 400;
  }

  &-alert-field {
    color: red;
    text-transform: uppercase;
  }

  &-help-text {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #333;
    margin-bottom: 26px;
  }
  &-signin-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 9px;
    h4 {
      margin-bottom: 0;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 500 !important;
      font-size: 15px !important;
      color: #757575;
      a {
        color: #3161f1 !important;
      }
    }
  }
}

.DOBModal {
  &-field-container {
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin: 30px 10px;
  }

  &-field-label {
    font-weight: 500;
    width: 150px;
  }

  &-field-input {
    flex-grow: 1;

    input {
      border-radius: 5px;
      font-weight: 400;
      padding: 5px 30px;
      width: 100%;
    }

    .ant-picker {
      width: 100%;
      input { padding: 5px 25px; }
    }

    .ant-input[disabled] {
      color: black !important;
    }
  }
}

.AccountLogoutDropdown {
  &-container {
    align-items: center;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.103);
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 250px;
    padding: 20px 0;
  }

  &-avatar {
    margin: 10px 0;

    .ant-avatar {
      background: white;
      border: 1px solid #f2f2f2;
    }
  }

  &-title {
    font-size: 1.1rem;
    font-weight: 400;
    margin: 2px 0;
  }

  &-email {
    color: rgba(0, 0, 0, 0.56);
  }

  &-policy {
    align-items: center;
    color: gray;
    display: flex;
    font-size: 0.7rem;
    font-weight: 300;
    justify-content: space-evenly;
  }

  &-dot {
    background: gray;
    border-radius: 50%;
    height: 3px;
    margin: 0 7px;
    width: 3px;
  }

  &-logout {
    align-items: center;
    color: gray;
    cursor: pointer;
    display: flex;

    i {
      font-size: 1.2rem;
      font-weight: 600;
      margin: 0 10px;
    }

    &:hover {
      color: var(--theme-primary);
      text-decoration: underline;
    }
  }
}

@import '~react-sortable-tree/style.css';

.rst__moveHandle,
.rst__loadingHandle {
  background: #b9b6c6;
  background-size: contain;
  border: unset;
  box-shadow: none;
  height: 0 !important;
  margin-top: 10px;
  mask-image: url('/menu-outlined.svg');
  mask-size: cover;
  width: 14px;

  &:hover {
    background: var(--theme-primary);
  }
}

.rst__node {
  align-items: center;
  display: flex;
  height: auto !important;
  justify-content: center;
  min-height: 30px !important;

  div {
    height: auto !important;
    position: relative;
  }

  .rst__moveHandle,
  .rst__loadingHandle {
    height: 0 !important;
  }

  &:hover .rst__moveHandle,
  .rst__loadingHandle {
    height: 14px !important;
    width: 14px !important;
  }
}

.rst__rowWrapper {
  padding: 0;
}

.rst__rowContents,
.rst__lineChildren,
.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after,
.rst__lineChildren::after {
  all: unset;
}

.rst__nodeContent {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  transform: translateX(-40px);
}

.rst__collapseButton {
  background: #b9b6c6;
  background-size: contain;
  margin-top: 1.5px;
  mask-image: url('/down-outlined.svg');
  mask-size: cover;
}

.rst__expandButton {
  background: #b9b6c6;
  background-size: contain;
  margin-top: 1px;
  mask-image: url('/right-outlined.svg');
  mask-size: cover;
}

.rst__collapseButton,
.rst__expandButton {
  border: unset;
  border-radius: none;
  box-shadow: none;
  height: 14px;
  position: absolute;
  top: 10px;
  transform: translateX(43px);
  width: 14px;
  z-index: 2000;

  &:hover {
    background: var(--theme-primary);
    height: 14px;
    position: absolute;
    top: 10px;
    transform: translateX(43px);
    width: 14px;
  }
}

.rst__collapseButton:focus,
.rst__expandButton:focus {
  box-shadow: none;
}

.rst__collapseButton:hover:not(:active),
.rst__expandButton:hover:not(:active) {
  background-size: contain;
  height: 14px;
  width: 14px;
}

.rst__rowLandingPad::before,
.rst__rowCancelPad::before {
  background-color: #e0dfe2;
  border: unset;
  box-shadow: none;
}

@import '~react-calendar/dist/Calendar.css';

.react-calendar {
  border: unset;
  padding: 0 10px;

  .react-calendar__month-view button {
    border-radius: 50%;
    font-size: 0.75rem;
  }
}

.react-calendar__navigation {
  button:disabled {
    background-color: inherit;
    color: #f0f0f0;
  }
}

.react-calendar__tile:disabled {
  background: inherit;
  border-radius: 0;
  color: rgb(209, 209, 209);
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  &:hover {
    background-color: #dedde5;
  }
}

.react-calendar__month-view__weekdays__weekday abbr {
  cursor: default;
  font-size: 0.7rem;
  font-weight: 600;
  text-decoration: none;
}

.react-calendar__tile--active {
  background: var(--theme-primary) !important;
  color: white;
}

.react-calendar__navigation__label {
  color: #bcbac8;
}

.react-calendar__tile .react-calendar__tile--hasActive {
  color: white !important;
}

.react-calendar__navigation {
  color: gray;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

.ProfileItemView {
  &-container {
    align-items: flex-end;
    display: flex;
    margin: 25px 0;
    min-width: 100%;
  }

  &-label {
    color: gray;
    font-size: 0.78rem;
    font-weight: 500;
    min-width: 125px;
    padding-top: 6px;
    text-transform: uppercase;
  }

  &-value {
    font-size: 1.1rem;
    font-weight: 300;
    margin: 0 20px;
    min-width: 100px;
  }

  &-value-empty {
    color: #d1d0d8;
  }

  &-edit-btn {
    color: var(--theme-primary);
    cursor: pointer;
    font-size: 0.7rem;
    opacity: 0;
    padding-bottom: 4px;
    transition: all 0.5s ease;

    &:hover {
      color: var(--theme-light);
    }
  }

  &-container:hover &-edit-btn {
    opacity: 1;
  }
}

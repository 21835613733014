.UpcomingBadge {
  &-container {
    background: orange;
    border-radius: 5px;
    color: white;
    font-size: 9px;
    height: 20px;
    margin: 0 5px;
    padding: 3px 5px;
    text-transform: uppercase;
  }
}

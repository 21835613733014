.VerifyEmail {
  &-container {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100%;
  }

  &-title {
    color: gray;
    font-size: 1.4rem;
  }

  &-success {
    color: green;
  }

  &-error {
    color: #fd7289;
  }
}

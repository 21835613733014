.AppsDropdown {
  &-container {
    align-items: center;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.103);
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    width: 200px;
  }

  &-app-item {
    align-items: center;
    border-radius: 5px;
    color: gray;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 80px;
    justify-content: space-evenly;
    margin: 5px 7px;
    padding: 10px;
    width: 80px;

    &:hover {
      background: var(--theme-hover-light);
      color: black;
    }

    i {
      color: var(--theme-primary);
    }

    .ant-avatar {
      background: white;
      border: 1px solid #f2f2f2;
    }
  }
}

.FP-Success {
  text-align: center;
  &-body {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    max-width: 100vw;
    min-height: 100vh;
    a:hover {
      text-decoration: underline;
    }
  }

  &-content {
    margin: 40px 30px;
  }

  &-logo-container {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
  }

  &-card {
    background: #ffffff;
    box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    width: 474px;
    @media (max-width: 534px) {
      width: 100%;
    }

    &-title {
      font-family: Montserrat, sans-serif;
      font-weight: bold !important;
      font-size: 20px;
      line-height: 24px;
      color: #333 !important;
      margin-bottom: 32px !important;
    }

    .ant-card-body {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 43px 46px;
      @media (max-width: 534px) {
        padding: 43px 30px;
      }
    }
  }

  &-sign-in-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 9px;
    h4 {
      margin-bottom: 0;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 500 !important;
      font-size: 15px !important;
      color: #757575 !important;
      a {
        color: #3161f1 !important;
      }
    }
  }

  &-alert-field {
    color: red;
    text-transform: uppercase;
  }

  &-help-text {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #333;
    margin-bottom: 26px;
    width: 306px;
    display: inline-block;
  }
}

.withAccountLayout {
  &-container {
    background-color: #fbfbfb;
    min-height: 100vh;
  }

  &-main-container {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 60px);

    @media screen and (min-width: 1120px) {
      padding-top: 20px;
    }
  }

  &-header {
    background-color: white;
    border-bottom: 1px solid var(--theme-secondary-light);
    box-shadow: inset 0 -1px 0 #64798f1f;
    display: flex;
    height: 60px;
    justify-content: center;
    left: 0;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 20;
  }

  &-body {
    background-color: white;
    border: 1px solid var(--theme-secondary-light);
    border-radius: 7px;
    box-shadow: inset 0 -1px 0 #00000026;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    min-height: 400px;
    width: 100%;

    @media screen and (min-width: 1120px) {
      flex-direction: row;
      margin-bottom: 20px;
      margin-top: 10px;
      width: 80%;
    }
  }

  &-sider {
    background-color: #fefefe;
    border-radius: 7px;
    border-right: 1px solid var(--theme-secondary-light);
    box-shadow: inset 0 -1px 0 #00000026;

    @media screen and (min-width: 1120px) {
      min-height: 100%;
      width: 380px;
    }
  }

  &-content {
    width: 100%;
  }
}

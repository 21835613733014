.Account {
  &-container {
    margin: 7% 10% 15%;
  }

  &-pic-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    padding: 0;

    @media screen and (min-width: 500px) {
      flex-direction: row;
      padding: 0 50px 0 0;
    }
  }

  &-meta {
    margin: 5px 50px;

    &-name {
      color: var(--theme-primary);
      font-size: 1.5rem;
      font-weight: 600;
    }

    &-tags {
      align-items: center;
      color: #b6b4c2;
      display: flex;
      font-weight: 300;
      margin-top: 15px;
    }

    &-apps {
      border: 1px solid #eeefee;
      border-radius: 5px;
      color: #888596;
      margin-bottom: 5px;
      margin-left: 10px;
      padding: 5px 10px;
    }

    &-highlight {
      color: black;
      font-size: 1.1rem;
    }
  }

  &-section-title {
    color: black;
    font-size: 1.4rem;
    margin: 50px 0 20px;
  }

  &-section-body {
    margin: 30px 0 70px;
  }

  &-dot {
    background-color: gray;
    border-radius: 50%;
    height: 5px;
    width: 5px;

    &-container {
      align-items: flex-start;
      display: flex;
      justify-content: space-around;
      margin-bottom: 6px;
      width: 70px;
    }
  }

  &-pic {
    position: relative;

    .ant-avatar {
      border: 1px solid #f2f2f2;
      cursor: pointer;
    }

    &-overlay {
      color: white;
      height: 0;
      overflow: hidden;
      width: 0;
    }

    &:hover &-overlay {
      align-items: center;
      background-color: rgba(0, 0, 0, 0.425);
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 10;
    }

    i {
      font-size: 1.8rem;
    }
  }

  &-email-container {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &-tag-success {
    background: green;
    border-radius: 5px;
    color: white;
    font-size: 0.7rem;
    height: 20px;
    margin-left: 7px;
    padding: 1px 5px;
  }

  &-tag-failed {
    background: gray;
    border-radius: 5px;
    color: white;
    font-size: 0.7rem;
    height: 20px;
    margin-left: 7px;
    padding: 1px 5px;
  }
}

.Header {
  &-container {
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  &-content {
    display: flex;
    max-width: 1200px;
    padding: 0 30px;
    width: 100%;

    @media screen and (min-width: 1120px) {
      padding: 0;
      width: 80%;
    }
  }

  &-logo-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-right: 10px;

    @media screen and (min-width: 1120px) {
      min-width: 300px;
      padding: 0 20px;
    }
  }

  &-logo {
    color: var(--theme-secondary-dark);
    display: flex;
    font-size: 1.3rem;
    font-weight: 600;
  }

  &-main-content {
    align-items: center;
    color: var(--theme-secondary-dark);
    display: flex;
    justify-content: flex-end;
    width: 100%;

    @media screen and (min-width: 500px) {
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  &-content-title {
    color: var(--theme-secondary-main);
    display: none;
    flex-grow: 1;
    font-size: 0.8;
    font-weight: 200;

    @media screen and (min-width: 500px) {
      display: block;
    }
  }

  &-apps {
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    font-size: 0.95rem;
    height: 50px;
    justify-content: center;
    margin: 0 0 0 5px;
    transition: all 0.3s ease;
    width: 50px;

    &:hover {
      background: var(--theme-secondary-light);
    }

    @media screen and (min-width: 500px) {
      margin: 0 0  0 15px;
    }
  }

  &-profile {
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    font-size: 1.2rem;
    height: 50px;
    justify-content: center;
    margin: 0 0 0 5px;
    padding: 5px;
    transition: all 0.3s ease;
    width: 50px;

    @media screen and (min-width: 500px) {
      margin: 0 0  0 15px;
    }

    i {
      margin-left: 5px;
    }

    &:hover {
      background: var(--theme-secondary-light) !important;
    }

    .ant-avatar {
      background: var(--theme-secondary-light);
      border: 1px solid #f2f2f2;
    }
  }

  &-email-alert {
    border-radius: 5px;
    bottom: 0;
    max-width: 1200px;
    position: absolute;
    transform: translateY(30px);
    width: 80%;
  }

  &-send-email {
    color: var(--theme-primary);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &-app-font {
    color: black;
    font-size: 1.05rem;
    font-weight: 300;
    margin: 6px 0 0 5px;
  }
}
